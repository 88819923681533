@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: rgba(25, 72, 152, 1);
}

html *{
  font-family: "Montserrat", sans-serif;
  @apply select-none text-primary-100;
}

svg{
  @apply shrink-0;
}

.swal2-confirm{
  background-color: var(--primary) !important;
}
.swal2-styled{
    box-shadow: none !important;
}
.swal2-icon{
    border-color: var(--primary) !important;
    color: var(--primary) !important;
}

.guests td{
  @apply p-2 border text-xs;
}

.swal2-html-container{
  color: var(--primary) !important;
}